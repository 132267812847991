.mock {
    width: 100%;
    min-height: 20rem;
    height: auto;
    padding-bottom: 2rem;
    /* background-color: red; */

    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
}

.mock-animation {
    transition: 2s ease-in-out;
    animation: mockAnimation 2s infinite linear alternate;
    position: relative;
}

.mock-card {
    flex: 1;
    min-width: 15%;
    height: 20rem;
    border-radius: 1rem;
    background-color: #ececec;
}

.mock-card-big {
    flex: 1;
    min-width: 100%;
    height: 25rem;
    border-radius: 1rem;
    background-color: #ececec;
}

@keyframes mockAnimation {
    0% {
        background-color: #fcfcfc;
    } 100% {
        background-color: #cacaca;
    }
}